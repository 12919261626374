import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`I bought thegrepper.com on a whim, but after checking the manual for grep one too many times I started looking for alternatives. `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/ggreer/the_silver_searcher"
        }}>{`Ag, also known as the Silver Searcher`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`ag is better tho`}</MDXTag>
      <MDXTag name="p" components={components}>{`But for real. Everything from the order it takes its arguments, to the maintainers’ emphasis on mneumonics, even how it highlights search results in yellow for you — it’s just better.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It’s also very fast. Much faster than ack, apparently. There’s a chapter about it on `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://conqueringthecommandline.com/book/ack_ag"
        }}>{`Conquering the Command Line`}</MDXTag>{` that unfortunately I don’t look at often enough.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Get on that ag y’all.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/ag-is-better-tho",
  "date": "2018-11-14",
  "title": "I Use Ag Tho",
  "image": "2018-11-14-except-that-i-use-ag.png",
  "sharpImage": true,
  "imgOverlay": "linear-gradient(to bottom, rgb(0, 0, 0, 0.6), rgb(67, 67, 67, 0.2))",
  "tags": ["command-line", "workflow"],
  "excerpt": "I bought thegrepper.com on a whim, but after checking the manual for grep one too many times I started looking for alternatives.",
  "published": true
};
      